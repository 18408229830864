<template>
  <div class="beatFeedBack">
    <div v-title data-title="高额返水"></div>
    <!-- <van-nav-bar
      title="高额返水"
      left-arrow
      :fixed="true"
      @click-left="gotoback"
    /> -->

    <div class="imgbox">
      <img :src="`${imgUrl}pcbb/bg-beatFeedBack.png`" />
    </div>

    <div class="container">
      <div
        style="display: flex;align-items: center;justify-content: center;margin:15px 0 0 0"
      >
        <img :src="`${imgUrl}pcbb/backwater-title.png`" width="160" />
      </div>
      <div class="backwater">
        <div class="container">
          <div style="width: 20%">
            <div class="grade" v-for="(item, index) in VipClass" :key="index">
              {{ item.level }}
            </div>
          </div>
          <div style="width: 80%">
            <div class="gradeNum" v-for="(v, i) in VipClass" :key="i">
              <div
                class="gradeNumItem"
                :class="i == 0 ? 'colortext' : i % 2 == 0 ? 'bgcolor' : ''"
                v-for="(j, k) in v.data"
                :key="k"
              >
                {{ j.value }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        style="color:var(--w-colortext2);font-size:14px;margin:0 10px;line-height: 24px;"
      >
        注:若有活动临时提高返水，则以活动规则为准。以上为场馆最高返水比例，具体返水金额请以实际游戏为准，其它疑问请联系7*24在线客服
        咨询。
      </div>
    </div>

    <div class="rule-big-box" style="margin-top:10px;position: relative;">
      <div class="rule-top-img"></div>

      <div class="rule-middle-img" style="padding:0px 30px">
        <h2
          style="text-align: center;position: absolute;top: 10px;left: 50%;transform: translateX(-50%);font-size: 15px;color: #333;background-color: #fff;"
        >
          【不返水游戏列表】
        </h2>

        <table style="margin-top:-5px">
          <tbody>
            <tr
              style="background: linear-gradient(0deg, #2A6AFD, #63C6FF);font-weight:400"
            >
              <td style="color:#fff">场馆类型</td>
              <td style="color:#fff">场馆名称</td>
              <td style="color:#fff">游戏名称</td>
            </tr>
            <tr style="font-weight:400">
              <td
                style="border-bottom: 1px solid #E9E9E9;font-weight:400"
                :rowspan="3"
              >
                彩票
              </td>
              <td>双赢彩票</td>
              <td>双赢彩票</td>
            </tr>
            <tr style="font-weight:400">
              <td style="border-left:0;font-weight:400">TCG彩票</td>
              <td>TCG彩票</td>
            </tr>
            <tr style="font-weight:400">
              <td style="border-left:0;font-weight:400">VR彩票</td>
              <td>VR彩票</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="rule-bottom-img" style="margin-top:-10px"></div>
    </div>

    <div class="rule-big-box">
      <div class="rule-top-img">
        <img
          src="../assets/img/pcbb/rules_1.png"
          v-if="cueTheme == 'dattheme'"
        />
        <img :src="`${imgUrl}pcbb/rules.png`" v-else />
      </div>
      <div class="rule-middle-img">
        <!-- 活动详情 -->
        <div class="rule-detail">
          <div class="rule-detail-title" style="margin-top:-10px"></div>
          <div class="activity_rules">
            <ul>
              <li>
                <span>1.</span>
                返水优惠发放金额根据玩家当天北京时间00:00 —
                23:59之间的有效投注进行计算，每日返水将会在次日的17:00 —
                19:00发放。其它疑问请联系7*24在线客服咨询。
              </li>
              <li>
                <span>2.</span>
                根据北京时间玩家投注的情况，当天所有的投注额返水将在注单结算当天结束后24小时内发放，体育场馆和电竞场馆注单以结算时间计算，例如:北京时间11月1日玩家已结算注单的返水，会在北京时间11月2日24小时内发放，有任何疑问请咨询7×24小时在线客服。
              </li>
              <li>
                <span>3.</span>
                返水按照有效投注进行计算，在所有场馆中，任何注单取消或本金退还以及在同一游戏中同时投注对冲盘口，将不计算在有效投注。部分游戏不参与返水优惠活动；体育有效投注按照输赢的金额进行计算（例如:下注100元，如赢派彩20元，有效投注为20元；如输本金100元，有效投注为100元）。
              </li>
              <li>
                <span>4.</span>
                游戏返水需投注1倍流水即可提款，每日返水总额最低为1元，总额向下取整。
              </li>
              <li>
                <span>5.</span>
                本优惠可与其他红利优惠同时享有（除去部分标注不与返水共享的优惠）。
              </li>
              <li>
                <span>6.</span>
                为避免文字理解差异，贝宝体育保留本活动最终解释权。
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="rule-bottom-img"></div>
    </div>
  </div>
</template>

<script>
import { getBeatFeedBack } from '../network/api'

export default {
  name: '',
  components: {},
  data() {
    return {
      VipClass: '',
      cueTheme: '',
    }
  },
  mounted() {
    this.getBeatFeedBack()
    this.cueTheme = window.localStorage.getItem('cueTheme')
  },
  methods: {
    getBeatFeedBack() {
      let data = '?type=' + 'BeatFeedBack'
      getBeatFeedBack(data).then((res) => {
        if (res.status_code === 200) {
          this.VipClass = res.message
          let data = []
          this.VipClass[0].data.forEach((v, i) => {
            data.push({ value: v.name })
          })
          this.VipClass.unshift({ level: '等级', data })
        }
      })
    },
    gotoback() {
      if (window.localStorage.getItem('opentype') == 'h5') {
        uni.navigateBack({})
      } else {
        uni.getEnv((res) => {
          uni.postMessage({
            data: {
              isclose: 'true',
            },
          })
        })
      }
    },
  },
}
</script>

<style lang="less">
@import '../assets/css/rulestyle.less';

body {
  background-color: #f7f8fa !important;
}

.van-icon .van-icon-arrow-left .van-nav-bar__arrow {
  color: #000 !important;
}

.van-nav-bar .van-icon {
  color: #000;
}

.imgbox {
  > img {
    width: 100%;
    display: flex;
  }
}

.beatFeedBack {
  box-shadow: 0px 1px 6px 0px #d7dae2;

  .container {
    margin: 0 10px;
    overflow: hidden;
    background-color: var(--w-bgcolor);
    border-radius: 13px;
    border: 1px solid var(--w-bordercolor1);

    .backwater {
      border-radius: 13px;
      width: 96%;
      background-color: var(--w-bgcolor);
      margin: 10px auto;

      .top {
        border-radius: 13px;
        overflow: hidden;
        display: flex;

        div {
          text-align: center;
          width: 14.28%;
          box-sizing: border-box;
          height: 46px;
          font-size: 12px;
          padding: 0 5px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #b2987f;
          border-bottom: 1px solid var(--w-bordercolor);
          border-right: 1px solid var(--w-bordercolor);
          padding-top: 15px;
          box-sizing: border-box;
          background-color: var(--w-bgcolor2);

          &:nth-child(1) {
            background: #f6e9db;
            vertical-align: top;
          }

          &:last-child {
            border-right: none;
          }
        }
      }

      .container {
        display: flex;
        border-radius: 13px;

        .grade {
          background-color: var(--w-bgcolor);
          border-right: 1px solid #e9e9e9;

          &:nth-child(odd) {
            background: var(--w-bgcolor2);
          }

          &:nth-child(1) {
            background: #5194fb;
            color: #ffffff;
          }

          box-sizing: border-box;
          height: 46px;
          font-size: 12px;
          font-family: PingFang SC;
          font-weight: 400;
          color: var(--li-colortext);
          padding: 0 5px;
          display: flex;
          align-items: center;
          text-align: center;
          justify-content: center;
        }

        .gradeNum {
          display: flex;
          align-items: center;
          text-align: center;

          // justify-content: center;
          .gradeNumItem {
            border-right: 1px solid #eeeeee;
            display: flex;
            align-items: center;
            text-align: center;
            justify-content: center;
            // width: 17.28%;
            flex: 1;
            height: 46px;
            // line-height: 46px;
            text-align: center;
            box-sizing: border-box;
            font-size: 12px;
            font-family: PingFang SC;
            font-weight: 400;
            color: var(--w-colortext1);

            &:last-child {
              border-right: none;
            }
          }

          &:nth-child(odd) {
            background: var(--w-bgcolor);
          }
        }
      }
    }
  }
}

.colortext {
  font-family: PingFang SC !important;
  font-weight: 400 !important;
  color: #fff !important;
  background: #5194fb !important;
}

.bgcolor {
  background: var(--w-bgcolor2) !important;
}

// [data-theme='dattheme'] {
//   background: #181920 !important;
//   border-color: #fff !important;
//   color: #fff;
// }
</style>
